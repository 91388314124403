<template>
  <sdCards headless style="min-height: 70vh; margin-bottom: 0; overflow: auto">
    <!--<pre v-if="userPartnerInfo && userData">{{ treeData }}</pre>-->
    <div class="tree__header">
      <router-link :to="'/partners'"> <sdFeatherIcons type="arrow-left" size="30" /> </router-link>
      <h2>{{ i18n.t('partners.tree') }}</h2>
    </div>

    <blocks-tree
      :data="treeData"
      :horizontal="true"
      :collapsable="true"
      :props="{ label: 'name_surname', children: 'children', key: 'key', expand: 'expand' }"
      v-if="loading || partnersData.length"
    >
      <template #node="{ data }">
        <div class="tree-tabs">
          <div class="tree-tabs__header" @click="expandData(data)">
            <div :class="`tree-tabs__image tree-tabs__image-${data.branch}`">
              <p>
                {{ data.name_surname[0].toUpperCase()
                }}{{
                  data.name_surname.length > 2
                    ? data.name_surname[data.name_surname.indexOf(' ', 1) + 1].toUpperCase()
                    : ''
                }}
              </p>
            </div>
            <p class="tree-tabs__name">
              <span>{{ data.name_surname }}</span>
              {{ data.referal_code ? `(${data.referal_code})` : '' }}
            </p>
          </div>
          <div v-if="data.email && data.isFullData" class="tree-tabs__data">
            <p class="tree-tabs__title">{{ i18n.t('partners.branch') }}</p>
            <p class="tree-tabs__description">
              {{
                data.branch == 1
                  ? i18n.t('start.main_branch')
                  : data.branch == 2
                  ? i18n.t('start.lateralBranch')
                  : i18n.t('start.subsidiary_branch', { branch: data.branch - 2 })
              }}
            </p>
            <p class="tree-tabs__title">{{ i18n.t('partners.email') }}</p>
            <p class="tree-tabs__description">{{ data.email }}</p>
            <p class="tree-tabs__title">{{ i18n.t('partners.invest') }}</p>
            <p class="tree-tabs__description">{{ data.invested }}</p>
            <p class="tree-tabs__title">
              {{ data.leader_status > 18 ? i18n.t('partners.topLevel') : i18n.t('partners.level') }}
            </p>
            <p class="tree-tabs__description">
              {{ data.leader_status }}
            </p>
          </div>
          <!--<span class="org-tree-node-btn custom" v-if="data.children" @click="openNextTab(true, data)"> </span>

          <span class="custom__line" v-if="data.children"> </span>-->
        </div>
      </template>
    </blocks-tree>
    <div class="loading-data" v-else>
      <a-spin size="large"></a-spin>
    </div>
  </sdCards>
</template>

<script>
import { RecordViewWrapper } from './style';
import { Main, TableWrapper } from '../styled';
import { useStore } from 'vuex';
import { computed, onMounted, defineAsyncComponent } from 'vue';
import { useI18n } from 'vue-i18n';
const beWithUs = defineAsyncComponent(() => import('./overview/BeWithUs'));

const ViewPage = {
  name: 'ViewPage',
  components: { RecordViewWrapper, Main, TableWrapper, beWithUs },
  setup() {
    const i18n = useI18n();
    const { state, dispatch } = useStore();

    const partnersData = computed(() => state.partners.partnersData);
    const loading = computed(() => state.partners.loading);
    const treeData = computed(() => {
      return {
        last_name: i18n.t('partners.me'),
        name: i18n.t('partners.me'),
        middle_name: i18n.t('partners.me'),
        childLevel: 0,
        expand: false,
        name_surname: i18n.t('partners.me'),
        has_referals: partnersData.value.length > 1 ? true : false,
        isFullData: true,
        key: 1,
        referal_code: '',
        children: partnersData.value,
        branch: 1,
      };
    });

    const openNextTab = (expanded, record) => {
      if (!record.expand) {
        record.expand = !record.expand;
        record.expanded = true;
      }
    };

    const expandData = (data) => {
      data.isFullData = !data.isFullData;
    };

    onMounted(() => {
      if (partnersData.value.length < 1) {
        dispatch('axiosPartnersGetData');
      }
    });
    return {
      i18n,
      treeData,
      expandData,
      loading,
      partnersData,
      openNextTab,
    };
  },
};

export default ViewPage;
</script>
<style lang="scss">
.org-tree-node-btn {
  width: 26px;
  height: 26px;
  border-radius: 5px;
  background: rgba(122, 118, 255, 1);
  color: #fff;
  cursor: pointer;
  margin-top: -14px !important;
  margin-left: 7px !important;
  &:hover {
    background: rgba(122, 118, 255, 1);
  }
  &.custom {
    left: calc(100% + 15px);
  }
}
.org-tree-node-spin {
  position: absolute;
  width: 26px;
  height: 26px;
  border-radius: 5px;
  background: #e6e6ff;
  margin-top: -14px !important;
  margin-left: 7px !important;
  left: calc(100% + 15px);
  padding: 3px;
  top: 50%;
  z-index: 2;
}
.custom__line {
  position: absolute;
  width: 24px;
  height: 1px;
  background: #ddd;
  top: 50%;
  left: calc(100% + 15px);
}
.horizontal .org-tree-node:not(:first-child):before,
.horizontal .org-tree-node:not(:last-child):after {
  border-left: 2px solid #ddd;
}
.horizontal .org-tree-node:not(:only-child):after {
  border-top: 2px solid #ddd;
}
.tree-tabs {
  position: relative;
  width: 250px;
  padding: 5px;

  &__name {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 0;
  }
  &__data {
    text-align: left;
    max-width: 250px;
    border-top: 1px solid #d7dcea;
    margin-top: 10px;
  }
  &__title {
    margin-bottom: 0;
    color: #908f9e;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-top: 15px;
  }
  &__description {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0;
    word-wrap: break-word;
  }
  &__header {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  &__image {
    border-radius: 50%;
    padding: 5px;
    overflow: hidden;
    min-width: 40px;
    min-height: 40px;
    max-height: 40px;
    max-width: 40px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #d7dcea;
    p {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.5);
      font-weight: 600;
      margin-bottom: 0;
    }
  }
  &__image {
    &-1 {
      background: #e0c3fc;
    }
    &-2 {
      background: #ddbdfc;
    }
    &-3 {
      background: #cbb2fe;
    }
    &-4 {
      background: #bbadff;
    }
    &-5 {
      background: #ada7ff;
    }
    &-6 {
      background: #9fa0ff;
    }
    &-7 {
      background: #8e94f2;
    }
    &-8 {
      background: #8187dc;
    }
    &-9 {
      background: #757bc8;
    }
    &-10 {
      background: #6d73c5;
    }
    &-11 {
      background: #5f65bf;
    }
    &-12 {
      background: #5057b9;
    }
    &-13 {
      background: #464daf;
    }
    &-14 {
      background: #377aa1;
    }
  }
}
.loading-data {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
}
.tree__header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  h2 {
    font-weight: bold;
    font-size: 36px;
    line-height: 40px;
    color: #272443;
    margin-bottom: 0;
  }
  a {
    margin: 10px 20px 0 0;
  }
}
.org-tree > .org-tree-node > .org-tree-node-label {
  max-width: 130px;
}
.tree-tabs {
  max-width: 200px;
  p {
    max-width: 130px;
    word-wrap: break-word;

    white-space: pre-wrap;
  }
  span {
  }
}
</style>
